'use client'

import * as React from 'react'
import {ReCaptchaProvider as BaseProvider} from 'next-recaptcha-v3'

export function ReCaptchaProvider({children}: {children: React.ReactNode}) {
  const reCaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY

  if (!reCaptchaKey) {
    throw new Error('Missing NEXT_PUBLIC_RECAPTCHA_SITE_KEY environment variable')
  }

  return (
    <BaseProvider
      reCaptchaKey={reCaptchaKey}
      useEnterprise={false}
      useRecaptchaNet={true}
      language="en"
    >
      {children}
    </BaseProvider>
  )
}
